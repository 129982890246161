export const deliveryStatus = {
  PENDING: 'PENDING',
  PLACED: 'PLACED',
  PICKED: 'PICKED',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
  NEXT: 'NEXT',
  DELIVERED: 'DELIVERED',
  REFUND_PENDING: 'REFUND_PENDING',
  MISSED: 'MISSED',
  CANCELLED: 'CANCELLED',
  UNKNOWN: 'UNKNOWN',
} as const;

export default deliveryStatus;

export type DeliveryStatus = (typeof deliveryStatus)[keyof typeof deliveryStatus];
