import React from 'react';
import { Card } from '@johnlewispartnership/wtr-ingredients/ingredients/Card';
import { useWtrSelector } from 'redux/hooks';
import { getStatus } from 'redux/modules/orders/selectors/get-order';
import orderStatus from 'constants/orderStatus';
import classNames from 'classnames';
import { ProgressIndicator } from '@johnlewispartnership/wtr-ingredients/ingredients/ProgressIndicator';
import urls from 'constants/urls';
import { getDeliveryTrackerSteps } from 'redux/modules/delivery-tracking/selectors/get-delivery-tracker';
import { useMatch } from 'react-router-dom';
import styles from './OrderCard.scss';
import OrderDetails from './OrderDetails/OrderDetails';
import OrderCardFooter from './OrderCardFooter/OrderCardFooter';
import OrderStatus from './OrderStatus/OrderStatus';

export interface OrderCardProps {
  orderId: string;
}

const OrderCard = ({ orderId }: OrderCardProps) => {
  const status = useWtrSelector(state => getStatus(state, orderId));
  const deliveryTrackerSteps = useWtrSelector(state => getDeliveryTrackerSteps(state, orderId));
  const isViewOrderPage = !!useMatch(urls.viewOrder);

  const isPaymentFailed = status === orderStatus.PAYMENT_FAILED;

  if (!orderId) {
    return null;
  }

  return (
    <Card
      data-testid={`order-card-id-${orderId}`}
      className={classNames(styles.orderCard, {
        [styles.paymentFailedCard]: isPaymentFailed,
        [styles.viewOrderPage]: isViewOrderPage,
      })}
      footer={<OrderCardFooter orderId={orderId} />}
    >
      <div className={styles.orderCardContent}>
        <div className={styles.topRow}>
          <OrderStatus orderId={orderId} />
        </div>
        <div className={styles.bottomRow}>
          <OrderDetails orderId={orderId} />
          <div className={styles.deliveryTrackerContainer}>
            <div className={styles.deliveryTracker}>
              {deliveryTrackerSteps && (
                <ProgressIndicator steps={deliveryTrackerSteps} data-testid="wismo-step-tracker" />
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OrderCard;
