import orderStatus from 'constants/orderStatus';
import { createSelector } from 'reselect';
import { deliveryStatus as DeliveryStatuses } from 'constants/deliveryStatus';
import { isEligibleForDeliveryTracking } from 'redux/modules/order/selectors/is-eligible-for-delivery-tracking';
import { getCustomerOrderById } from 'redux/modules/entities/selectors/customer-orders';
import { SlotTypes } from 'constants/fulfilmentTypes';
import { getDeliveryStatusByOrderId } from './get-delivery-status';
import {
  cancelledDeliveryTracker,
  completedDeliveryTracker,
  deliveredDeliveryTracker,
  missedDeliveryTracker,
  outForDeliveryTracker,
  preparingDeliveryTracker,
  youreNextDeliveryTracker,
} from '../constants';

export const getDeliveryTrackerSteps = createSelector(
  getDeliveryStatusByOrderId,
  getCustomerOrderById,
  (deliveryStatusById, order) => {
    // eligible for WISMO tracking
    if (
      order.slots[0].type === SlotTypes.GROCERY_COLLECTION ||
      order.slots[0].type === SlotTypes.ENTERTAINING_COLLECTION
    ) {
      return null;
    }

    if (isEligibleForDeliveryTracking(order.status, order.slots[0])) {
      if (deliveryStatusById === DeliveryStatuses.OUT_FOR_DELIVERY) {
        return outForDeliveryTracker;
      }
      if (deliveryStatusById === DeliveryStatuses.NEXT) {
        return youreNextDeliveryTracker;
      }
      if (deliveryStatusById === DeliveryStatuses.MISSED) {
        return missedDeliveryTracker;
      }
      if (deliveryStatusById === DeliveryStatuses.DELIVERED) {
        return deliveredDeliveryTracker;
      }
      return preparingDeliveryTracker;
    }
    if (order.status === orderStatus.COMPLETED) {
      return completedDeliveryTracker;
    }
    if (order.status === orderStatus.CANCELLED) {
      return cancelledDeliveryTracker;
    }
    return null;
  },
);
