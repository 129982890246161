import { SlotTypes } from 'constants/fulfilmentTypes';
import orderStatus, { OrderStatus } from 'constants/orderStatus';
import { Slot } from '../state/initial-state';
import { isSlotToday } from './is-slot-today';

const deliveryTrackableStatus: OrderStatus[] = [
  orderStatus.PICKED,
  orderStatus.PAYMENT_FAILED,
  orderStatus.PAID,
  orderStatus.COMPLETED,
];

export function isEligibleForDeliveryTracking(status: OrderStatus, slot: Partial<Slot>): boolean {
  const hasWismoTrackableOrderStatus = deliveryTrackableStatus.includes(status);
  const isDeliveryOrder = slot.type === SlotTypes.DELIVERY;

  return hasWismoTrackableOrderStatus && isDeliveryOrder && isSlotToday(slot.startDateTime);
}
