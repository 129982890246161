import classnames from 'classnames';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { Link, useMatch } from 'react-router-dom';

import { Warning } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';
import Alert from '@johnlewispartnership/wtr-ingredients/ingredients/Alert';
import { LinkAsButton } from '@johnlewispartnership/wtr-ingredients/ingredients/LinkAsButton';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import { GoToPaymentPageButton } from 'components/ResolveOrderPayment/GoToPaymentPageButton';
import {
  CUSTOMER_CARE_PHONE_NUMBER,
  CUSTOMER_CARE_TEL_HREF,
  PAYMENT_FAILED_PHONE_NUMBER,
  PAYMENT_FAILED_TEL_HREF,
} from 'constants/checkout/telephone-numbers';
import deliveryStatusType from 'constants/deliveryStatus';
import { FailedPaymentResolutionType } from 'constants/FailedPaymentResolutionType';
import { SlotTypes } from 'constants/fulfilmentTypes';
import orderStatusType from 'constants/orderStatus';
import urls, { viewOrderURL } from 'constants/urls';
import { useWtrDispatch, useWtrSelector } from 'redux/hooks';
import { confirmCancelAmendOrder } from 'redux/modules/amend-order/actions/confirm-cancel-amend-order';
import { getFailedPaymentResolutionTypeForId } from 'redux/modules/checkout/selectors/get-failed-order-payment';
import {
  getDeliveryRunningLateByOrderId,
  getDeliveryStatusByOrderId,
} from 'redux/modules/delivery-tracking/selectors';
import { isEligibleForDeliveryTracking } from 'redux/modules/order/selectors/is-eligible-for-delivery-tracking';
import {
  getAmendOrderCutOff,
  getSlotStartTime,
  getSlotType,
  getStatus,
  isAmendable,
} from 'redux/modules/orders/selectors/get-order';
import { orderIsAmending } from 'redux/modules/orders/selectors/get-order-amend-status';
import { getOrderTypeById } from 'redux/modules/orders/selectors/get-order-type-by-id';
import { isSeasonalSlotDate, seasonalCutOffDateTime } from 'utils/checkout/is-seasonal-slot-date';
import { matchOrderType } from 'utils/checkout/order-type';
import { dayjs, isAfter, isBefore, isSameDay } from 'utils/date';
import { formatAmendCutOffDateTime } from 'utils/format-amend-cutoff-date';
import styles from './OrderCardFooter.scss';

export interface OrderCardFooterProps {
  orderId: string;
}

const orderStatusText = (orderStatusInfo: ReactNode, customClassName?: string): React.ReactNode => {
  return orderStatusInfo ? (
    <Typography
      element="p"
      styleAs="paragraphSmallLight"
      noMargins
      className={classnames([customClassName])}
      data-testid="order-status-information"
    >
      {orderStatusInfo}
    </Typography>
  ) : null;
};

const getViewButtonTheme = (amending: boolean, orderCanBeAmended: boolean) => {
  if (amending) {
    return {
      label: 'View order',
      theme: 'secondaryLight',
    };
  }
  if (orderCanBeAmended) {
    return {
      label: 'View or amend order',
      theme: 'primary',
    };
  }
  return {
    label: 'View order',
    theme: 'secondary',
  };
};

const cta = {
  payNowButton: (orderId: string): React.ReactNode => {
    return (
      <GoToPaymentPageButton
        key="goto-payment-page-button"
        customerOrderId={orderId}
        className={styles.payNowButton}
      />
    );
  },
  viewOrderButton: (isAmending: boolean, orderCanBeAmended: boolean, orderId: string) => {
    const viewOrderButtonTheme = getViewButtonTheme(isAmending, orderCanBeAmended);
    return (
      <LinkAsButton
        key="view-order-button"
        component={Link}
        data-testid={`view-order-button-${orderId}`}
        to={viewOrderURL(orderId)}
        role="link"
        label={viewOrderButtonTheme.label}
        theme={viewOrderButtonTheme.theme as never}
      />
    );
  },
  viewOrderLink: (orderId: string): React.ReactNode => {
    return (
      <TextLink
        key="view-order-payment-failed-button"
        underline="always"
        component={Link}
        data-testid={`view-order-payment-failed-${orderId}`}
        to={viewOrderURL(orderId)}
      >
        View order
      </TextLink>
    );
  },
  discardAmendsLink: (orderId: string, onClick: () => void): React.ReactNode => {
    return (
      <TextLink
        key="discard-amends-button"
        underline="always"
        component="button"
        data-testid={`discard-amends-button-${orderId}`}
        onClick={onClick}
        light
      >
        Discard amends
      </TextLink>
    );
  },
};

const message = {
  paymentFailed: (failedPaymentResolutionType: FailedPaymentResolutionType) => {
    const content =
      failedPaymentResolutionType === FailedPaymentResolutionType.selfServe ? (
        <span>
          <b>Payment failed</b>, please pay to receive this order
        </span>
      ) : (
        <span>
          <b>Payment failed</b>. To pay now, call us on{' '}
          <TextLink className={styles.phoneNumber} href={PAYMENT_FAILED_TEL_HREF}>
            {PAYMENT_FAILED_PHONE_NUMBER}
          </TextLink>
        </span>
      );

    return orderStatusText(
      <>
        <Warning className={styles.errorIcon} aria-hidden />
        <>{content}</>
      </>,
      styles.paymentFailedOrderStatusText,
    );
  },
  missedDelivery: (): React.ReactNode => {
    return orderStatusText(
      <>
        We missed you! Please call customer care on{' '}
        <TextLink className={styles.phoneNumber} href={CUSTOMER_CARE_TEL_HREF}>
          {CUSTOMER_CARE_PHONE_NUMBER}
        </TextLink>
      </>,
    );
  },
  unableToAmend: (): React.ReactNode => {
    return orderStatusText('Unable to amend – order in progress');
  },
  list: {
    seasonalSlot: (): React.ReactNode => {
      return orderStatusText(
        <>
          Amend cut-off for Christmas Entertaining items is{' '}
          <strong>{seasonalCutOffDateTime}</strong>
        </>,
      );
    },
    amending: (amendOrderCutOff: string): React.ReactNode => {
      return orderStatusText(
        <>
          Check out before <strong>{formatAmendCutOffDateTime(amendOrderCutOff)}</strong>
        </>,
      );
    },
    groceries: (amendOrderCutOff: string): (() => React.ReactNode) => {
      return () => (
        <>
          You have until <strong>{formatAmendCutOffDateTime(amendOrderCutOff)}</strong> to amend
          this order
        </>
      );
    },
    groceriesEntertaining: (): (() => React.ReactNode) => {
      return () => 'Notice times vary for Entertaining items';
    },
    entertainingCollection: (): (() => React.ReactNode) => {
      return () => 'Please check individual notice times before amending Entertaining orders';
    },
  },
  view: {
    seasonalSlot: (
      amendOrderCutOff: string,
      isEntertainingCollectionSlot: boolean,
    ): React.ReactNode => {
      return orderStatusText(
        <>
          Entertaining items have individual notice times (amend cut-off for Christmas Entertaining
          items is <b>{seasonalCutOffDateTime}</b>).{' '}
          {!isEntertainingCollectionSlot ? (
            <>
              You can amend Grocery items in your order until{' '}
              <b>{formatAmendCutOffDateTime(amendOrderCutOff)}</b>.
            </>
          ) : null}
        </>,
      );
    },
    groceries: (amendOrderCutOff: string): (() => React.ReactNode) => {
      return () => (
        <>
          You can amend your order until <b>{formatAmendCutOffDateTime(amendOrderCutOff)}</b>.
        </>
      );
    },
    groceriesEntertaining: (amendOrderCutOff: string): (() => React.ReactNode) => {
      return () => (
        <>
          You can amend Grocery items in your order until{' '}
          <b>{formatAmendCutOffDateTime(amendOrderCutOff)}</b>. Entertaining items have individual
          notice times and cannot be removed once in progress.
        </>
      );
    },
    entertainingCollection: (): (() => React.ReactNode) => {
      return () => <b>Please check individual notice times before amending Entertaining orders.</b>;
    },
  },
};

const RunningLateAlert = () => (
  <div className={styles.alertContainer}>
    <Alert
      role="alert"
      aria-live="polite"
      type="warning"
      title="Sorry, we’re running late"
      message="Your delivery time has been updated"
    />
  </div>
);

const isCutOffApproaching = (amendable: boolean, amendOrderCutOff: string | undefined) => {
  if (!amendOrderCutOff) {
    return false;
  }
  const amendOrderCutoffDate = dayjs(amendOrderCutOff).tz();
  const now = dayjs().tz();
  return amendable && isSameDay(amendOrderCutoffDate, now) && isAfter(amendOrderCutoffDate, now);
};

const isRunningLate = (isDeliveryRunningLate: boolean | undefined, orderStatus: string) => {
  return (
    isDeliveryRunningLate &&
    (orderStatus === orderStatusType.PICKED || orderStatus === orderStatusType.PAID)
  );
};

const OrderCardFooter = ({ orderId }: OrderCardFooterProps) => {
  const isViewOrderPage = !!useMatch(urls.viewOrder);
  const dispatch = useWtrDispatch();
  const amendable = useWtrSelector(state => isAmendable(state, orderId));
  const isAmending = useWtrSelector(state => orderIsAmending(state, orderId));
  const slotType = useWtrSelector(state => getSlotType(state, orderId));
  const amendOrderCutOff: string | undefined = useWtrSelector(state =>
    getAmendOrderCutOff(state, orderId),
  );
  const orderType = useWtrSelector(state => getOrderTypeById(state, orderId));
  const slotStartTime = useWtrSelector(state => getSlotStartTime(state, orderId));
  const orderStatus = useWtrSelector(state => getStatus(state, orderId));
  const deliveryStatus = useWtrSelector(state => getDeliveryStatusByOrderId(state, orderId));
  const isDeliveryRunningLate = useWtrSelector(state =>
    getDeliveryRunningLateByOrderId(state, orderId),
  );
  const failedPaymentResolutionType = useWtrSelector(state =>
    getFailedPaymentResolutionTypeForId(state, orderId),
  );
  const isSeasonalSlot = isSeasonalSlotDate(slotStartTime);
  const isPaymentFailed = orderStatus === orderStatusType.PAYMENT_FAILED;
  const isMissedDelivery = deliveryStatus === deliveryStatusType.MISSED;
  const canPayNow =
    isPaymentFailed && failedPaymentResolutionType === FailedPaymentResolutionType.selfServe;
  const orderCanBeAmended = amendable && !isPaymentFailed;
  const eligibleForDeliveryTracking = isEligibleForDeliveryTracking(orderStatus, {
    type: slotType,
    startDateTime: slotStartTime,
  });
  const runningLate = isRunningLate(isDeliveryRunningLate, orderStatus);
  const highlightCutOffApproaching = isCutOffApproaching(amendable, amendOrderCutOff);

  const getListOrderStatusInfo = useCallback(() => {
    if (isSeasonalSlot) {
      return message.list.seasonalSlot();
    }
    if (!amendOrderCutOff) {
      return null;
    }
    if (isAmending) {
      return message.list.amending(amendOrderCutOff);
    }
    return orderStatusText(
      matchOrderType<ReactNode>(orderType, {
        onGroceries: message.list.groceries(amendOrderCutOff),
        onEntertaining: message.list.entertainingCollection(),
        onGroceriesEntertaining: message.list.groceriesEntertaining(),
      }),
    );
  }, [amendOrderCutOff, isAmending, isSeasonalSlot, orderType]);

  const getViewOrderStatusInfo = useCallback(() => {
    if (!amendOrderCutOff) {
      return null;
    }
    if (isSeasonalSlot) {
      const isEntertainingCollectionSlot = slotType === SlotTypes.ENTERTAINING_COLLECTION;
      return message.view.seasonalSlot(amendOrderCutOff, isEntertainingCollectionSlot);
    }

    return orderStatusText(
      matchOrderType<ReactNode>(orderType, {
        onGroceries: message.view.groceries(amendOrderCutOff),
        onEntertaining: message.view.entertainingCollection(),
        onGroceriesEntertaining: message.view.groceriesEntertaining(amendOrderCutOff),
      }),
    );
  }, [amendOrderCutOff, isSeasonalSlot, orderType, slotType]);

  const orderStatusInfo = useMemo(() => {
    // payment failed status info - shared
    if (isPaymentFailed && failedPaymentResolutionType !== null) {
      return message.paymentFailed(failedPaymentResolutionType);
    }

    // delivered/completed - show nothing
    if (
      orderStatus === orderStatusType.COMPLETED ||
      orderStatus === orderStatusType.CANCELLED ||
      deliveryStatus === deliveryStatusType.DELIVERED
    ) {
      return null;
    }

    // delivery missed - shared
    if (eligibleForDeliveryTracking) {
      return isMissedDelivery ? message.missedDelivery() : null;
    }

    // only after the cut off and before end of slot date, and before it's picked - shared
    if (!amendable && !isAmending) {
      return (orderStatus === orderStatusType.PLACED || orderStatus === orderStatusType.FULFIL) &&
        isBefore(dayjs().tz(), dayjs(slotStartTime).tz().endOf('day'))
        ? message.unableToAmend()
        : null;
    }
    if (amendable || isAmending) {
      return isViewOrderPage ? getViewOrderStatusInfo() : getListOrderStatusInfo();
    }
    return null;
  }, [
    isPaymentFailed,
    failedPaymentResolutionType,
    orderStatus,
    deliveryStatus,
    eligibleForDeliveryTracking,
    amendable,
    isAmending,
    isMissedDelivery,
    slotStartTime,
    isViewOrderPage,
    getViewOrderStatusInfo,
    getListOrderStatusInfo,
  ]);

  const buttons = useMemo(() => {
    const actionButtons: ReactNode[] = [];

    const onCancelChanges = () => {
      dispatch(confirmCancelAmendOrder());
    };

    if (isViewOrderPage) {
      if (canPayNow) {
        actionButtons.push(cta.payNowButton(orderId));
      }
    } else {
      // GLP and My Orders
      if (isAmending) {
        actionButtons.push(cta.discardAmendsLink(orderId, onCancelChanges));
      }

      if (canPayNow) {
        actionButtons.push(cta.payNowButton(orderId), cta.viewOrderLink(orderId));
      } else if (!isMissedDelivery) {
        actionButtons.push(cta.viewOrderButton(isAmending, orderCanBeAmended, orderId));
      }
    }

    return actionButtons.length === 0 ? null : (
      <div
        data-testid="order-card-buttons"
        className={classnames([
          styles.actionButtons,
          {
            [styles.actionButtonsPaymentFailed]: isPaymentFailed,
          },
        ])}
      >
        {actionButtons}
      </div>
    );
  }, [
    canPayNow,
    dispatch,
    isAmending,
    isMissedDelivery,
    isPaymentFailed,
    isViewOrderPage,
    orderCanBeAmended,
    orderId,
  ]);

  return (
    <>
      {(orderStatusInfo || buttons || runningLate) && (
        <div
          className={classnames([
            styles.orderCardFooter,
            {
              [styles.closeToCutOff]: highlightCutOffApproaching,
              [styles.orderCardFooterDark]: isAmending,
              [styles.orderCardFooterRed]: isPaymentFailed,
            },
          ])}
          data-testid="order-status-info-wrapper"
        >
          {(orderStatusInfo || buttons) && (
            <div className={styles.orderCardFooterDetails}>
              {orderStatusInfo ? (
                <span className={styles.orderStatusInfo} data-testid="order-status-info">
                  {orderStatusInfo}
                </span>
              ) : null}
              {buttons}
            </div>
          )}
          {runningLate ? <RunningLateAlert /> : null}
        </div>
      )}
    </>
  );
};

export default OrderCardFooter;
