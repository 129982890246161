import React from 'react';
import {
  colourPrimaryGrey,
  colourWhite,
} from '@johnlewispartnership/wtr-ingredients/foundations/colours';
import { Step } from '@johnlewispartnership/wtr-ingredients/ingredients/ProgressIndicator/ProgressIndicator';
import { RemoveSolid, SuccessSolid } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

export const preparingDeliveryTracker: Step[] = [
  {
    label: 'Order confirmed',
    isCompleted: true,
  },
  {
    label: 'Preparing',
    current: true,
  },
  {
    label: 'Out for delivery',
  },
  {
    label: 'Delivered',
  },
];

export const outForDeliveryTracker: Step[] = [
  {
    label: 'Order confirmed',
    isCompleted: true,
  },
  {
    label: 'Prepared',
    isCompleted: true,
  },
  {
    label: 'Out for delivery',
    current: true,
  },
  {
    label: 'Delivered',
  },
];

export const youreNextDeliveryTracker: Step[] = [
  {
    label: 'Order confirmed',
    isCompleted: true,
  },
  {
    label: 'Prepared',
    isCompleted: true,
  },
  {
    label: 'Out for delivery',
    isCompleted: true,
  },
  {
    label: "You're next!",
    current: true,
  },
];

export const missedDeliveryTracker: Step[] = [
  {
    label: 'Order confirmed',
    customIcon: <SuccessSolid size="large" data-testid="success-icon-1" />,
    backgroundColor: colourPrimaryGrey,
    color: colourWhite,
  },
  {
    label: 'Prepared',
    customIcon: <SuccessSolid size="large" data-testid="success-icon-2" />,
    backgroundColor: colourPrimaryGrey,
    color: colourWhite,
  },
  {
    label: 'Out for delivery',
    customIcon: <SuccessSolid size="large" data-testid="success-icon-2" />,
    backgroundColor: colourPrimaryGrey,
    color: colourWhite,
  },
  {
    label: 'We missed you',
    customIcon: <RemoveSolid size="large" data-testid="cancelled-icon-1" />,
    backgroundColor: colourWhite,
    color: colourPrimaryGrey,
    current: true,
  },
];

export const deliveredDeliveryTracker: Step[] = [
  {
    label: 'Order confirmed',
    isCompleted: true,
  },
  {
    label: 'Prepared',
    isCompleted: true,
  },
  {
    label: 'Out for delivery',
    isCompleted: true,
  },
  {
    label: 'Delivered',
    isCompleted: true,
  },
];

export const completedDeliveryTracker: Step[] = [
  {
    label: 'Order confirmed',
    isCompleted: true,
  },
  {
    label: 'Prepared',
    isCompleted: true,
  },
  {
    label: 'Out for delivery',
    isCompleted: true,
  },
  {
    label: 'Delivered',
    isCompleted: true,
  },
];

export const cancelledDeliveryTracker: Step[] = [
  {
    label: 'Order confirmed',
    customIcon: <SuccessSolid size="large" data-testid="success-icon-1" />,
    backgroundColor: colourPrimaryGrey,
    color: colourWhite,
  },
  {
    label: 'Prepared',
    customIcon: <SuccessSolid size="large" data-testid="success-icon-2" />,
    backgroundColor: colourPrimaryGrey,
    color: colourWhite,
  },
  {
    label: 'Cancelled',
    customIcon: <RemoveSolid size="large" data-testid="cancelled-icon-1" />,
    backgroundColor: colourWhite,
    color: colourPrimaryGrey,
    current: true,
  },
  {
    label: 'Delivered',
  },
];
